.site-footer {
    /* background-color: var(--color2a); */
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: #737373;
    word-break: break-all;
    /* z-index: 11; */
}

.site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5
}

.site-footer hr.small {
    margin: 20px 0
}

.site-footer h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px
}

.site-footer a {
    color: #737373;
}

.site-footer a:hover {
    color: var(--color0);
    text-decoration: none;
}

.footer-links {
    padding-left: 0;
    list-style: none
}

.footer-links li {
    display: block
}

.footer-links a {
    color: #737373
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    color: var(--color0);
    text-decoration: none;
}

.footer-links span:active,
.footer-links span:focus,
.footer-links span:hover {
    color: var(--color0);
    text-decoration: none;
    cursor: pointer;
}

.footer-links.inline li {
    display: inline-block
}


.site-footer .social-icons a {
    width: 100px;
    height: 100px;
    line-height: 40px;
    margin: 6px 0 0 6px;
    border-radius: 100%;
    background-color: #33353d
}


.copyright-text {
    margin: 0
}

@media (max-width:991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px
    }
}

@media (max-width:767px) {
    .site-footer {
        padding-bottom: 0
    }

    .site-footer .copyright-text,
    .site-footer .social-icons {
        text-align: center
    }
}

.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.social-icons li {
    display: inline-block;
    margin-bottom: 4px
}

.social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px
}

.social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: block;
    width: 100px;
    height: 100px;
    text-align: center;
    margin-right: 8px;
    /* border-radius: 100%; */
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
    color: #fff;
    background-color: var(--color0);
}

.social-icons a.wechat img {
    width: 100%;
}

@media (max-width:767px) {
    .social-icons li.title {
        display: block;
        margin-right: 0;
        font-weight: 600
    }
}

.copyright {
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 0 10px;
    word-break: break-word;
}

.footer-container {
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
    /* display: grid; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;


    -webkit-box-align: start;


    -webkit-align-items: flex-start;


       -moz-box-align: start;


        -ms-flex-align: start;


            align-items: flex-start;
    justify-items: center;
    grid-gap: 10px;
}



@media screen and (min-width: 768px) {
    .footer-container .footer-item {
        max-width: 50%;
    }
}

/* disclaims */
.disclaims-box {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

#disclaims {
    display: "none";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.envelope {
    width: 200px;
    height: 100px;
    background: #ddd;
    -webkit-box-shadow:
        0 0 1px rgba(0, 0, 0, 0.5),
        0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow:
        0 0 1px rgba(0, 0, 0, 0.5),
        0 1px 3px rgba(0, 0, 0, 0.25);
    position: relative;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    perspective: 800px;
}

.envelope:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid rgba(0, 0, 0, 0.25);
    border-width: 45px 100px;
    border-top-color: transparent;
    z-index: 2;
}

.envelope .flap {
    position: absolute;
    width: 100%;
    height: 0;
    border: 0 solid transparent;
    border-width: 50px 100px;
    z-index: 3;
}

.envelope .flap.front {
    border-left-color: #eee;
    border-right-color: #eee;
    border-bottom-color: #ccc;
    z-index: 3;
}

.envelope .flap.front:after {
    content: '';
    width: 100%;
    height: 0;
    position: absolute;
    left: -100px;
    bottom: -50px;
    border: 0 solid transparent;
    border-width: 49px 100px;
    border-bottom-color: #eee;
}

.envelope .flap.top {
    border-top-width: 55px;
    border-top-color: #aaa;
    z-index: 3;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform-origin-y: top;
    transform-origin-y: top;
    -webkit-perspective: 800;
    -moz-perspective: 800;
    perspective: 800;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.envelope.open .flap.top {
    -webkit-animation-name: flip;
    -moz-animation-name: flip;
    -o-animation-name: flip;
    animation-name: flip;
}

.envelope .flap.top:after {
    content: '';
    position: absolute;
    left: -100px;
    /* border-left-width */
    top: -55px;
    /* border-top-width */
    width: 100%;
    height: 0;
    border: 0 solid transparent;
    border-width: 54px 100px;
    border-top-color: #eee;
}

.envelope .letter {
    position: absolute;
    width: 194px;
    height: 84px;
    background: #fff;
    top: 8px;
    left: 3px;
    border: 1px solid #ccc;
    z-index: 1;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.envelope.open .letter {
    -webkit-animation-name: remove;
    -moz-animation-name: remove;
    -o-animation-name: remove;
    animation-name: remove;
}

.envelope .letter:before,
.envelope .letter:after {
    content: '';
    position: absolute;
    width: 192px;
    height: 75%;
    left: -1px;
    background: #fff;
    border: 1px solid #ccc;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform-origin-y: top;
    transform-origin-y: top;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(10deg);
    -moz-transform: rotateX(10deg);
    transform: rotateX(10deg);
}

.envelope .letter:before {
    z-index: 1;
}

.envelope.open .letter:before {
    -webkit-animation-name: fold-up;
    -moz-animation-name: fold-up;
    -o-animation-name: fold-up;
    animation-name: fold-up;
}

.envelope .letter:after {
    -webkit-animation-delay: 5s;
    -moz-animation-delay: 5s;
    -o-animation-delay: 5s;
    animation-delay: 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transform-origin-y: bottom;
    transform-origin-y: bottom;
    -webkit-transform: rotateX(-5deg);
    -moz-transform: rotateX(-5deg);
    transform: rotateX(-5deg);
    bottom: 0;
}

.envelope.open .letter:after {
    -webkit-animation-name: fold-down;
    -moz-animation-name: fold-down;
    -o-animation-name: fold-down;
    animation-name: fold-down;
}

@-webkit-keyframes flip {
    100% {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
        z-index: 1;
    }
}

@-moz-keyframes flip {
    100% {
        -moz-transform: rotateX(180deg);
        transform: rotateX(180deg);
        z-index: 1;
    }
}

@-o-keyframes flip {
    100% {
        transform: rotateX(180deg);
        z-index: 1;
    }
}

@keyframes flip {
    100% {
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        transform: rotateX(180deg);
        z-index: 1;
    }
}

@-webkit-keyframes remove {
    50% {
        top: -120px;
    }

    100% {
        top: 8px;
        z-index: 3;
    }
}

@-moz-keyframes remove {
    50% {
        top: -120px;
    }

    100% {
        top: 8px;
        z-index: 3;
    }
}

@-o-keyframes remove {
    50% {
        top: -120px;
    }

    100% {
        top: 8px;
        z-index: 3;
    }
}

@keyframes remove {
    50% {
        top: -120px;
    }

    100% {
        top: 8px;
        z-index: 3;
    }
}

@-webkit-keyframes fold-up {
    from {}

    to {
        -webkit-transform: rotateX(140deg);
        transform: rotateX(140deg);
    }
}

@-moz-keyframes fold-up {
    from {}

    to {
        -moz-transform: rotateX(140deg);
        transform: rotateX(140deg);
    }
}

@-o-keyframes fold-up {
    from {}

    to {
        transform: rotateX(140deg);
    }
}

@keyframes fold-up {
    from {}

    to {
        -webkit-transform: rotateX(140deg);
        -moz-transform: rotateX(140deg);
        transform: rotateX(140deg);
    }
}

@-webkit-keyframes fold-down {
    from {}

    to {
        -webkit-transform: rotateX(-140deg);
        transform: rotateX(-140deg);
    }
}

@-moz-keyframes fold-down {
    from {}

    to {
        -moz-transform: rotateX(-140deg);
        transform: rotateX(-140deg);
    }
}

@-o-keyframes fold-down {
    from {}

    to {
        transform: rotateX(-140deg);
    }
}

@keyframes fold-down {
    from {}

    to {
        -webkit-transform: rotateX(-140deg);
        -moz-transform: rotateX(-140deg);
        transform: rotateX(-140deg);
    }
}

#reset {
    background: #eee;
    display: inline-block;
    margin-top: -100px;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 25px;
    background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ccc));
    background: -webkit-linear-gradient(#eee, #ccc);
    background: -moz-linear-gradient(#eee, #ccc);
    background: -o-linear-gradient(#eee, #ccc);
    background: linear-gradient(#eee, #ccc);
    color: #333;
    text-shadow: 0 1px 0 #fff;
    cursor: pointer;
    float: right;
}

#reset:hover {
    opacity: 0.8;
}

#reset:active {
    background: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#eee));
    background: -webkit-linear-gradient(#ccc, #eee);
    background: -moz-linear-gradient(#ccc, #eee);
    background: -o-linear-gradient(#ccc, #eee);
    background: linear-gradient(#ccc, #eee);
}

.disclaims-box {
    width: 100%;
}

.footer-item .wechat img {
    width: 100%;
    height: 100%;
    width: 100px;
    height: 100px;
}

/* .footer-item .text-about {

} */