/* html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-feature-settings: "liga", "kern";
    font-family: "Montserrat", "HelveticaNeueLTStd", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    line-height: 1.5;
} */

.contentContainer {
    display: none;
}

.galleryContainer {
    position: relative;
    font-family: "Montserrat", "HelveticaNeueLTStd", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.galleryBox {
    height: 100%;
    min-height: 90vh;
    padding-bottom: 100px;
    width: 100%;
}

.gallery-wrapper {
    overflow: hidden;
}

.fadein {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.fadein.lazyloaded {
    opacity: 1;
}

figure a,
figure img,.pswp__img {
    -webkit-touch-callout: none;
    pointer-events: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

figure {
    margin: 0;
}

figure[style] {
    max-width: 100%;
}

svg {
    max-width: 100%;
    height: auto;
    border: 0;
}


html:not(.touchevents) .gallery-caption {
    position: absolute;
    bottom: 4rem;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    font-size: 12px;
    color: rgba(255, 255, 255, 0);
    padding: 1.25em 1.5em;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: none;
}

@media (min-width: 640px) {
    html:not(.touchevents) .gallery-caption {
        font-size: 14px;
    }
}

html:not(.touchevents) .gallery-caption:before,
html:not(.touchevents) .gallery-caption:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: -1;
}

html:not(.touchevents) .gallery-caption:before {
    top: auto;
    height: 3px;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transition-delay: 0.6s;
    -moz-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

html:not(.touchevents) .gallery-caption:after {
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

@media screen and (min-width: 1200px) {
    html:not(.touchevents) .visible.gallery-caption,
    .gallery-item:hover .gallery-caption {
        color: white;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        -webkit-transition: all 0.3s ease 0.3s;
        -o-transition: all 0.3s ease 0.3s;
        -moz-transition: all 0.3s ease 0.3s;
        transition: all 0.3s ease 0.3s;
    }

    html:not(.touchevents) .visible.gallery-caption:before,
    .gallery-item:hover .gallery-caption:before {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
    }

    html:not(.touchevents) .visible.gallery-caption:after,
    .gallery-item:hover .gallery-caption:after {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    html:not(.touchevents) .gallery-caption:empty {
        display: none;
    }

}

.gallery-item a {
    position: relative;
}

.gallery-item a:before,
.gallery-item a:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0 solid rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    will-change: border;
    z-index: 10;
}

.gallery-item a.active:before,
.lazy-images .gallery-item a.image-lazyloaded:before,
html:not(.lazy-images) .gallery-item a:before {
    border-width: 0.5rem;
}

.gallery-item a.active:after,
.lazy-images .gallery-item a.image-lazyloaded:after,
html:not(.lazy-images) .gallery-item a:after {
    border-width: 2px;
}

.gallery-item a:after {
    margin: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.5);
    -webkit-clip-path: polygon(0 -webkit-calc(100% - 1rem), 0 100%, 1rem 100%, 1rem 0, 0 0, 0 1rem, 100% 1rem, 100% 0, -webkit-calc(100% - 1rem) 0, -webkit-calc(100% - 1rem) 100%, 100% 100%, 100% -webkit-calc(100% - 1rem));
    clip-path: polygon(0 -moz-calc(100% - 1rem), 0 100%, 1rem 100%, 1rem 0, 0 0, 0 1rem, 100% 1rem, 100% 0, -moz-calc(100% - 1rem) 0, -moz-calc(100% - 1rem) 100%, 100% 100%, 100% -moz-calc(100% - 1rem));
    clip-path: polygon(0 calc(100% - 1rem), 0 100%, 1rem 100%, 1rem 0, 0 0, 0 1rem, 100% 1rem, 100% 0, calc(100% - 1rem) 0, calc(100% - 1rem) 100%, 100% 100%, 100% calc(100% - 1rem));
}

.gallery-item a:hover:after {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    border-color: white;
}

.gallery {
    font-family: "Montserrat", "HelveticaNeueLTStd", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    width: 100%;
    display: grid;
    grid-template-rows: flow;
    grid-auto-flow: dense;
    overflow-y: auto;
    /* gap: 4px; */
    /* max-height: 100vh; */
}

.gallery::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

@media (max-width: 639px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 640px) and (max-width: 1019px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1020px) {
    .gallery {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1260px) and (max-width: 1599px) {
    .gallery {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (min-width: 1600px) {
    .gallery {
        grid-template-columns: repeat(6, 1fr);
    }
}

.gallery-item {
    position: relative;
    /* background-color: rgba(255, 255, 255, 0.5); */
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.gallery-item img,
.gallery-item a {
    display: block;
}

.vertical.gallery-item img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.horizontal.gallery-item img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

html:not(.touchevents) .visible.gallery-caption:after,
.gallery-item:hover .gallery-img {
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
    -webkit-transition: 0.3s ease-out;
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
}

.gallery-item a {
    display: block;
    height: 100%;
    width: 100%;
    padding: 1px;
}

[class*=list] .gallery-caption,
.gallery-size-thumbnail .gallery-caption {
    display: none;
}

::-moz-selection {
    color: white;
    background: black;
    text-shadow: none;
}

::selection {
    color: white;
    background: black;
    text-shadow: none;
}

.visible,
.invisible {
    opacity: 0.0;
    -webkit-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
}

.visible {
    opacity: 1.0;
}

.gallery.loadmore::after {
    position: absolute;
    content: "loading...";
    bottom: 0;
    left: 0;
    height: 50;
    width: 100%;
    text-align: center;
}

.load-box {
    position: relative;
    width: 100%;
}

#load {
    position: absolute;
    width: 300px;
    height: 36px;
    margin: 0 auto;
    left: 50%;
    top: 0;
    padding: 40px 0;
    margin-left: -150px;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

#load div {
    position: absolute;
    width: 20px;
    height: 36px;
    opacity: 0;
    font-family: Helvetica, Arial, sans-serif;
    animation: move 2s linear infinite;
    -o-animation: move 2s linear infinite;
    -moz-animation: move 2s linear infinite;
    -webkit-animation: move 2s linear infinite;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    color: var(--color1);
    /* color: #35C4F0; */
}

@keyframes move {
    0% {
        left: 0;
        opacity: 0;
    }

    35% {
        left: 41%;
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    65% {
        left: 59%;
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    100% {
        left: 100%;
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0;
    }
}

@-moz-keyframes move {
    0% {
        left: 0;
        opacity: 0;
    }

    35% {
        left: 41%;
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    65% {
        left: 59%;
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    100% {
        left: 100%;
        -moz-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0;
    }
}

@-webkit-keyframes move {
    0% {
        left: 0;
        opacity: 0;
    }

    35% {
        left: 41%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    65% {
        left: 59%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    100% {
        left: 100%;
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0;
    }
}

@-o-keyframes move {
    0% {
        left: 0;
        opacity: 0;
    }

    35% {
        left: 41%;
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    65% {
        left: 59%;
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 1;
    }

    100% {
        left: 100%;
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0;
    }
}

/*手机*/
@media screen and (max-width:600px) {
    .gallery-caption {
        display: none;
    }
}

/*平板*/
@media screen and (min-width:600px) and (max-width:960px) {
    .gallery-caption {
        display: none;
    }
}

/*PC*/
@media screen and (min-width:960px) {
    .gallery-caption {
        display: block;
    }
}


/** 定义photoSwipe样式*/

/* 收藏按钮 */
button.pswp__button--collect {
    font-size: 16px;
    color: #fff;
    margin-right: 1em;
    border-radius: 8px;
    min-width: 50px;
}

/* 缩放 指示器 */
.pswp__zoom-level-indicator {
    /* background: var(--color0); */
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    width: 50px;
    height: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    padding: 10px 5px;
    border-radius: 100%;
    text-align: center;
}

/* 添加下载 */
button.pswp__button--test-button {
    /* background: var(--color0) !important;
    font-size: 20px;
    color: #fff; */
}

/* 添加导航指示器 */
.pswp__bullets-indicator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    position: absolute;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.pswp__bullet {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    margin: 0 5px;
}

.pswp__bullet--active {
    background: var(--color0);
}

.pswp__button--autoplay {
    color: #fff;
    width: 4em;
    margin-left: 1em;
}