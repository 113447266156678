:root {
    --color0: #D18D00;
    --color0a: #ffaa00;
    --color0b: hsla(40, 100%, 41%, .1);
    --color0c: #ffaa00b3;
    --color0d: #f9b325;
    --color1: #FFFFFF;
    --color1a: #faebd7;
    --color2: #000000;
    --color2a: #1e1f26;
    --color2b: hsla(0, 0%, 100%, .3);
    --color2c: hsla(0, 0%, 100%, .1);
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* *::-webkit-scrollbar { 
    width: 0 !important;
    height: 8px;
} */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
            box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
}

::-webkit-scrollbar-thumb {
    opacity: 0.5;
    background-color: var(--color0);
    background-image: -webkit-linear-gradient(45deg, hsla(0, 0%, 100%, .4) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .4) 0, hsla(0, 0%, 100%, .4) 75%, transparent 0, transparent);
}

::-webkit-scrollbar-track {
    background: #fff6e4;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

/* .nav-container::-webkit-scrollbar-track {
    background-color: #131b25;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

.nav-container::-webkit-scrollbar-thumb {
    background-color: #ffffff47;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    width: 0;
    height: 0;
}
.nav-container{
    -ms-overflow-style: none; 
    overflow: -moz-scrollbars-none;
} */

.blockScreen {
    overflow: hidden;
}

.unblockScreen {
    overflow: auto;
}

.navigationContainer {
    position: relative;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 21;
}

.nav {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 100%;
}

.nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* height: 100vh; */
    position: relative;
    text-align: center;
    padding: 0 2em;
}

.background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.background {
    content: "";
    /* height: 100%; */
    height: -webkit-calc(100% - 50px);
    height: -moz-calc(100% - 50px);
    height: calc(100% - 50px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color2);
    background-image: -webkit-linear-gradient(var(--color2c) 1px, transparent 0),
        -webkit-linear-gradient(left, var(--color2c) 1px, transparent 0),
        -webkit-linear-gradient(var(--color2c) 1px, transparent 0),
        -webkit-linear-gradient(left, var(--color2c) 1px, transparent 0);
    background-image: -moz-linear-gradient(var(--color2c) 1px, transparent 0),
        -moz-linear-gradient(left, var(--color2c) 1px, transparent 0),
        -moz-linear-gradient(var(--color2c) 1px, transparent 0),
        -moz-linear-gradient(left, var(--color2c) 1px, transparent 0);
    background-image: -o-linear-gradient(var(--color2c) 1px, transparent 0),
        -o-linear-gradient(left, var(--color2c) 1px, transparent 0),
        -o-linear-gradient(var(--color2c) 1px, transparent 0),
        -o-linear-gradient(left, var(--color2c) 1px, transparent 0);
    background-image: linear-gradient(var(--color2c) 1px, transparent 0),
        linear-gradient(90deg, var(--color2c) 1px, transparent 0),
        linear-gradient(var(--color2c) 1px, transparent 0),
        linear-gradient(90deg, var(--color2c) 1px, transparent 0);
    -o-background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;
    background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;
}

.nav h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 5vw;
    margin: 0;
    padding-bottom: 0.5rem;
    letter-spacing: 0.3rem;
    color: var(--color0a);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 3;
    word-break: keep-all;
}

.nav h1:hover {
    -webkit-transform: translate3d(0, -10px, 22px);
    -moz-transform: translate3d(0, -10px, 22px);
    transform: translate3d(0, -10px, 22px);
    color: var(--color1);
    text-shadow: 0 0 10px var(--color1)
}

.nav h3.span {
    font-size: 3vw;
    letter-spacing: 0.7em;
    font-family: "ROBOTO", sans-serif;
    font-weight: 300;
    color: var(--color1);
    z-index: 4;
}

.nav h3 span:last-of-type {
    letter-spacing: 0;
}

/* .nav span:hover {
    color: var(--color1);
    font-weight: 500;
    font-size: 3.2vw;
} */

a {
    text-decoration: none;
}

.nav-box {
    width: 100%;
    position: relative;
}

.nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    background: #1e1f26;
    z-index: 10;
    -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 20;
    cursor: pointer;
    overflow-x: auto;
    overflow-y: hidden;
    /* display: inline-block; */
    /* white-space: nowrap; */
    width: 100%;
}

.nav-container--top-second {
    position: fixed !important;
    top: 0;
    left: 0;
}

.nav-tab {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    color: var(--color1);
    letter-spacing: 0.1rem;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 18px;
    cursor: pointer;
    min-width: 100px;
    /* padding: 0 5px; */
    text-decoration: none;
    font-weight: 400;
    text-align: center;
    line-height: 50px;
    max-height: 50px;
    max-width: 50%;
    display: inline-block;
    overflow: hidden;
}

.nav-tab:hover {
    color: var(--color1a);
    /* font-weight: bold; */
    /* font-size: 1.5vw; */
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /* padding-bottom: 5px; */
    border-bottom: 1px solid var(--color0);
    border-top: 1px solid var(--color0);
}

.nav-tab.active {
    /* font-size: 2vw; */
    /* font-weight: bold; */
    /* background-color: var(--color0); */
    color: var(--color0a);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /* border-bottom: 2px solid var(--color0a); */
    border-top: 1px solid var(--color0);
}

.nav-tab-slider {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--color1);
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
}

.nav h2,
.slider h2 {
    margin: 0;
}


.loader_en {
    margin-bottom: 1rem;
}

.loader_en span {
    font-family: serif;
    letter-spacing: 0;
    /* font-size: 2.2vw; */
}

@media screen and (min-width: 360px) {

    .nav h1,
    .slider h1 {
        font-size: 9vw;
    }

    .nav h2,
    .slider h2 {
        /* font-size: 2vw; */
        font-size: 20px;
        letter-spacing: 0.2vw;
    }

    .nav h3.span {
        font-size: 18px;
    }

    .nav .loader_en.span {
        font-size: 16px;
    }

    .nav-tab {
        /* font-size: 1.2vw; */
        font-size: 16px;
    }
}

@media (min-width: 800px) {

    .nav h1,
    .slider h1 {
        font-size: 8vw;
    }

    .nav h2,
    .slider h2 {
        font-size: 3vw;
        letter-spacing: 0.2vw;
    }

    .nav h3.span {
        font-size: 20px;
    }

    .nav .loader_en.span {
        font-size: 18px;
    }

    .nav-tab {
        /* font-size: 1.2vw; */
        font-size: 18px;
    }
}

@media (min-width: 1200px) {

    .nav h1,
    .slider h1 {
        font-size: 6vw;
    }

    .nav h3.span {
        font-size: 2.2vw;
    }

    .nav .loader_en.span {
        font-size: 1.8vw;
    }
}


.loader span {
    color: var(--color0);
    text-shadow: 0 0 0 var(--color0);
    -moz-animation: loading 1s ease-in-out infinite alternate;
    -o-animation: loading 1s ease-in-out infinite alternate;
    animation: loading 1s ease-in-out infinite alternate;
    -webkit-animation: loading 1s ease-in-out infinite alternate;
}


@-moz-keyframes loading {
    to {
        text-shadow: 20px 0 70px var(--color1);
        color: var(--color1);
    }
}

@-o-keyframes loading {
    to {
        text-shadow: 20px 0 70px var(--color1);
        color: var(--color1);
    }
}

@keyframes loading {
    to {
        text-shadow: 20px 0 70px var(--color1);
        color: var(--color1);
    }
}

@-webkit-keyframes loading {
    to {
        text-shadow: 20px 0 70px var(--color1);
        color: var(--color1);
    }
}

.waves-box {
    position: absolute;
    width: 100%;
    bottom: 50px;
    left: 0;
    z-index: 2;
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}


/* Animation */

.parallax>use {
    -webkit-animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    -moz-animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    -o-animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    -webkit-animation-delay: -2s;
    -moz-animation-delay: -2s;
    -o-animation-delay: -2s;
    animation-delay: -2s;
    -webkit-animation-duration: 7s;
    -moz-animation-duration: 7s;
    -o-animation-duration: 7s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    -webkit-animation-delay: -3s;
    -moz-animation-delay: -3s;
    -o-animation-delay: -3s;
    animation-delay: -3s;
    -webkit-animation-duration: 10s;
    -moz-animation-duration: 10s;
    -o-animation-duration: 10s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    -webkit-animation-delay: -4s;
    -moz-animation-delay: -4s;
    -o-animation-delay: -4s;
    animation-delay: -4s;
    -webkit-animation-duration: 13s;
    -moz-animation-duration: 13s;
    -o-animation-duration: 13s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    -webkit-animation-delay: -5s;
    -moz-animation-delay: -5s;
    -o-animation-delay: -5s;
    animation-delay: -5s;
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
    -o-animation-duration: 20s;
    animation-duration: 20s;
}

@-webkit-keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(85px, 0, 0);
        transform: translate3d(85px, 0, 0);
    }
}

@-moz-keyframes move-forever {
    0% {
        -moz-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        -moz-transform: translate3d(85px, 0, 0);
        transform: translate3d(85px, 0, 0);
    }
}

@-o-keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

@keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
        -moz-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(85px, 0, 0);
        -moz-transform: translate3d(85px, 0, 0);
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    /* .waves {
        height: 50px;
        min-height: 50px;
    } */
}

.ruler-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 2;
}

.ruler-box .ruler {
    /* position: absolute; */
    width: 100%;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 1px;
}

.ruler-box .line {
    min-width: 1px;
    max-width: 1px;
    width: 1px;
    height: 12px;
    background-color: var(--color1);
}

.ruler-box .line:nth-child(9n+1) {
    height: 20px;
}

/* .ruler-box .line:nth-child(90n+2) {
    height: 32px;
} */

.ruler-box .line:not(:last-child) {
    margin-right: 3px;
}

.scroll-tool {
    width: 100%;
}

.scroll-tool .MouseScroll {
    position: absolute;
    left: 50%;
    bottom: 50px;
    width: 100px;
    height: 100px;
    margin: 0 0 0 -50px;
    z-index: 2;
}

.scroll-tool .MouseScroll path.MouseScroll--chevron {
    -webkit-animation: ChevronAnimation 3s ease infinite;
    -moz-animation: ChevronAnimation 3s ease infinite;
    -o-animation: ChevronAnimation 3s ease infinite;
    animation: ChevronAnimation 3s ease infinite;
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
}

@-webkit-keyframes ChevronAnimation {
    0% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 1
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0
    }

    60% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 0
    }
}

@-moz-keyframes ChevronAnimation {
    0% {
        -moz-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 1
    }

    50% {
        -moz-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0
    }

    60% {
        -moz-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 0
    }
}

@-o-keyframes ChevronAnimation {
    0% {
        -o-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 1
    }

    50% {
        -o-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0
    }

    60% {
        -o-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 0
    }
}

@keyframes ChevronAnimation {
    0% {
        -webkit-transform: translateY(3px);
        -moz-transform: translateY(3px);
        -o-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 1
    }

    50% {
        -webkit-transform: translateY(8px);
        -moz-transform: translateY(8px);
        -o-transform: translateY(8px);
        transform: translateY(8px);
        opacity: 0
    }

    60% {
        -webkit-transform: translateY(3px);
        -moz-transform: translateY(3px);
        -o-transform: translateY(3px);
        transform: translateY(3px);
        opacity: 0
    }
}

.newNavTab {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: #1e1f26;
    z-index: 20;
    -webkit-box-shadow: 20px 20px 50px rgb(0 0 0 / 50%);
            box-shadow: 20px 20px 50px rgb(0 0 0 / 50%);
    -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.mouse-wheel-wrapper {
    width: 100%;
    /* margin: 80px auto; */
    white-space: nowrap;
    border-radius: 5px;
    overflow: hidden;
}

.mouse-wheel-wrapper .mouse-wheel-content {
    display: inline-block;
}

.mouse-wheel-wrapper .mouse-wheel-content .mouse-wheel-item {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    padding: 0 20px;
}