@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdr.ttf) format('truetype');
}
.share-container {
  position: fixed;
  right: 10px;
  bottom: 20%;
  z-index: 20;
}
.share-container *,
.share-container *::before,
.share-container *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.share-container a {
  color: #FFF;
  text-decoration: none;
}
.share-container ul {
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
}
.share-container ul li {
  color: #ffffff;
  line-height: 30px;
}
.share-container ul li span {
  float: right;
  font-size: 13px !important;
  border: 2px solid #ffffff;
  display: block;
  text-align: center;
  height: 30px;
  font-weight: bold;
  width: 30px;
  line-height: 25px;
  border-radius: 15px;
}
.share-container .close {
  outline: none;
  background-color: transparent;
  border: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transform: translateX(-60px) rotate(90deg);
}
.share-container .close.active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  opacity: 1;
  transform: translateX(0px) rotate(360deg);
}
.share-container .close .bar {
  display: inline-block;
  height: 2px;
  width: 20px;
  position: relative;
  margin-bottom: 5px;
  background-color: #FFF;
}
.share-container .close .bar:nth-of-type(1) {
  transform: rotate(45deg) translateY(9px);
}
.share-container .close .bar:nth-of-type(2) {
  transform: rotate(-45deg) translateY(-9px);
}
.share-container .share {
  opacity: 0.6;
  width: 50px;
  height: 50px;
  overflow: hidden;
  line-height: 20px;
  padding: 5px 0;
  border-radius: 4px;
  position: relative;
  background-color: var(--color0a);
  border: 1px solid #fff;
  transition: width 285ms cubic-bezier(0.4, 0, 0.2, 1) 0.2s, height 325ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  text-align: center;
  -webkit-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.35);
}
.share-container .share:hover {
  opacity: 1;
}
.share-container .share .toggle {
  color: #FFF;
  cursor: pointer;
  display: block;
  width: 100%;
}
.share-container .share.active {
  opacity: 1;
  width: 350px;
  height: 300px;
  min-height: 70px;
  border-radius: 25px;
  background-image: linear-gradient(180deg, var(--color0a) 50%, var(--color2a) 50%);
  transition: width 285ms cubic-bezier(0.4, 0, 0.2, 1) 0.1s, height 325ms cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  -webkit-box-shadow: 0px 5px 3px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 3px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 3px 4px rgba(0, 0, 0, 0.25);
}
.share-container .share.active .content {
  opacity: 1;
  transform-origin: center center;
  height: 250px;
  transform: scale(1, 1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
}
.share-container .share.active .content .text {
  transition-delay: 0.6s;
  opacity: 1;
  transform: translateY(0px);
}
.share-container .share.active .toggle span {
  line-height: 50px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  transform: translateX(-128px);
}
.share-container .share span {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.share-container .share.active span {
  font-size: 18px;
}
.share-container .share .content {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  width: 100%;
  height: 0px;
  padding: 10px 30px;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: var(--color2a);
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.share-container .share .content .text small {
  color: #fff;
}
.share-container .share .content .text p {
  color: #fff;
}
.share-container .share .content .text {
  transition: 300ms all;
  opacity: 0;
  transform: translateY(20px);
}
.share-container .share .content .text .sharelinkBtn {
  transition: 300ms all;
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  text-align: center;
  border-radius: 25px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: #fff;
  animation: tada 0s;
}
.share-container .share .content .text .sharelinkBtn:hover {
  border: 2px solid var(--color0a);
  color: var(--color0a);
  animation-duration: 1s;
}
.share-container .share .content .text .sharelinkBtn:active {
  animation: none;
}
.share-container .copysuccess {
  border: 2px solid var(--color1a) !important;
  color: var(--color1a) !important;
  background-color: var(--color0) !important;
}
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
.share-container .shareQR {
  width: 100%;
  text-align: center;
  height: 100px;
}
.share-container .sharelink {
  color: #FFF;
  user-select: text;
  word-wrap: break-word;
  word-break: break-all;
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rightclickmenu {
  z-index: 9999;
  margin: 0;
  min-width: 80px;
  background-color: #000;
  background-color: var(--color2a);
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  line-height: 30px;
  position: fixed;
  display: none;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
}
.rightclickmenu:hover {
  box-shadow: 0 0 5px 1px #fff;
}
.rightclickmenu ul {
  list-style: none;
  margin: 0;
}
.rightclickmenu ul li {
  list-style: none;
  margin: 0;
  padding: 0 2px;
  word-break: keep-all;
}
.rightclickmenu ul li:hover {
  color: var(--color0d);
  font-weight: 500;
}
